<template>
  <div>
    <div class="header">
      <div class="session-id">
        <div class="iconBox">
          <v-icon>mdi-lightning-bolt</v-icon>
        </div>
        <div>
          <div class="title">
            Session #109
          </div>
          <div class="sub-title">
            AB-DC-LA-173737701/3
          </div>
        </div>
      </div>
      <div>
        <!-- <v-btn
          color="gray"
          outlined
          class="mx-2"
        >
          Export
        </v-btn> -->
        <download-file-buttons :small="false" />
      </div>
    </div>
    <div clas>
      <v-tabs v-model="tab">
        <v-tab
          v-for="tabItem in tabOptions"
          :key="tabItem.tab"
        >
          {{ tabItem.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in tabOptions"
          :key="item.tab"
        >
          <div class="mt-5">
            <div v-if="item.content == 'overview'">
              <overview-tab :item="selectedRow" />
            </div>
            <div v-else-if="item.content == 'energy_detail'">
              <energy-detail-tab />
            </div>
            <div v-else-if="item.content == 'billing'">
              <billing-tab />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    DownloadFileButtons: () =>
      import("@/components/base/DownloadFileButtons.vue"),
    OverviewTab: () => import("../utils/OverviewTab.vue"),
    EnergyDetailTab: () => import("../utils/EnergyDetailTab.vue"),
    BillingTab: () => import("../utils/BillingTab"),
  },
  props: {
    selectedRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab: null,

      tabOptions: [
        {
          tab: this.$t("overview"),
          content: "overview",
        },
        {
          tab: this.$t("energy_detail"),
          content: "energy_detail",
        },
        {
          tab: this.$t("billing"),
          content: "billing",
        },
      ],
    };
  },
};
</script>
<style lang="sass" scoped>
.header
  margin-bottom: 1rem
  display: flex
  justify-content: space-between
  align-items: baseline
  .session-id
    display: flex
    align-items: center
    gap: 1rem
    .iconBox
      background: #dcdcdc
      padding: 1rem
      border-radius: 100%
    .title
      font-size: 20px !important
      font-weight: 600 !important
</style>
